import { createApp } from 'vue'
import * as Klaro from "klaro"
import App from './App.vue'
import router from './router'
import store from './store';

import i18n from './i18n'

import "bootstrap"
import '@/scss/main.scss';
import "@/assets/main.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faCircleInfo, faSkull, faFlag, faHouseFlag } from '@fortawesome/free-solid-svg-icons'
// /* add icons to the library */
library.add(faCircleInfo, faSkull, faFlag, faHouseFlag)

// import global componentes
import BaseErrorAlert from './components/Base/BaseErrorAlert.vue'
import TheNavbar from '@/components/Layout/TheNavbar.vue';

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.component('base-error-alert', BaseErrorAlert)
.component('the-navbar', TheNavbar)
.use(router)
.use(store)
.use(i18n)
.mount('#app')

// klaro! configuration
const klaroConfig = {
    htmlTexts: true,
    acceptAll: true,
    cookieExpiresAfterDays: 365,
    mustConsent: true,
    storageMethod: 'cookie',
    // cookieDomain: '.events-4-us.com', // fuehrt vermutlich zu Problemen, darum deaktiviert - 2023-10-18
    cookiePath: '/',
    testing: false,
    lang: 'de',
    translations: {
        de: {
            // privacyPolicyUrl: '/datenschutz-view',
            consentNotice: {
                description:
                    'Hier kannst du einsehen und anpassen, welche Dienste und Cookies wir ' + 
                    'einsetzten, um Informationen zu sammeln bzw. die Webseite nutzbar machen.<br>' +
                    'In unserer <a href=\'/datenschutz-view\'>Datenschutzerklärung</a> kannst du ' +
                    'detaillierte Informationen zu den Diensten finden und hast die Möglichkeit ' + 
                    'zur nachträglichen Anpassung der gewählten Einstellungen.',
            },       
            consentModal: {
                description:
                    'Hier kannst du einsehen und anpassen, welche Dienste und Cookies wir ' + 
                    'einsetzten, um Informationen zu sammeln bzw. die Webseite nutzbar machen.<br>' +
                    'In unserer <a href=\'/datenschutz-view\'>Datenschutzerklärung</a> kannst du ' +
                    'detaillierte Informationen zu den Diensten finden und hast die Möglichkeit ' + 
                    'zur nachträglichen Anpassung der gewählten Einstellungen.',
            },                 
            googleAnalytics: {
                title: "Google Analytics",
                description: "Der Analytics-Service von Google.",
            },
            googleAdsense: {
                title: "Google Adsense",
                description: "Der Werbeservice von Google.",
            },
            purposes: {
                function: {
                    title: 'Funktional',
                    description: 'Für den Betrieb der Website zwingend erforderlich.'
                },
                advertising: {
                    title: 'Werbung',
                    description: 'Anzeigen von Werbung.'
                },                
                analytics: {
                    title: 'Analyse',
                    description: 'Erfassung von Besucherstatistiken.'
                },
                security: {
                    title: 'Sicherheit'
                }
            }
        }      
    },
    services: [
        {
            name: 'klaro',
            purposes: ['function'],
            cookies: [
                /^klaro?/
            ],  
            required: true,          
        },
        {
            name: 'google-tag-manager',
            purposes: ['advertising', 'analytics'],
            onAccept: `
                // we notify the tag manager about all services that were accepted. You can define
                // a custom event in GTM to load the service if consent was given.
                for(let k of Object.keys(opts.consents)){
                    if (opts.consents[k]){
                        let eventName = 'klaro-'+k+'-accepted'
                        dataLayer.push({'event': eventName})
                    }
                }
                // if consent for Google Analytics was granted we enable analytics storage
                if (opts.consents[opts.vars.googleAnalyticsName || 'google-analytics']){
                    console.log("Google analytics usage was granted")
                    gtag('consent', 'update', {'analytics_storage': 'granted'})
                }
                // if consent for Google Adsense was granted we enable adsense storage
                if (opts.consents[opts.vars.adStorageName || 'google-adsense']){
                    console.log("Google adsense usage was granted")
                    gtag('consent', 'update', {'ad_storage': 'granted'})
                }
            `,
            onInit: `
                // initialization code here (will be executed only once per page-load)
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments)}
                gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
                gtag('set', 'ads_data_redaction', true)
            `,
            onDecline: `
                // initialization code here (will be executed only once per page-load)
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments)}
                gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
                gtag('set', 'ads_data_redaction', true)
            `,
            vars: {
                googleAnalytics: 'google-analytics',
                googleAdsense: 'google-adsense'
            },
            onlyOnce: true,
            optOut: false,
            // required: true,
        },
        {
            name: 'google-analytics',
            purposes: ['analytics'],
            cookies: [
                // /^_ga(_.*)?/,
                // /^_gat(_.*)?/,
                // /^_gid(_.*)?/,
                [/^_ga(_.*)?/, '/', '.events-4-us.com'],           
            ],
            onAccept: `
                // we grant analytics storage
                gtag('consent', 'update', {
                    'analytics_storage': 'granted',
                })
            `,
            onDecline: `
                // we deny analytics storage
                gtag('consent', 'update', {
                    'analytics_storage': 'denied',
                })
            `            
        },
        {
            name: 'google-adsense',
            purposes: ['advertising'],
            // cookies: [        
            // ],
            onAccept: `
                // we grant ad storage and personalization
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                })
            `,
            onDecline: `
                // we decline ad storage and personalization
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied'
                })
            `,
        }
    ],

};

// klaro! setup
window.klaro = Klaro;
window.klaroConfig = klaroConfig;
Klaro.setup(klaroConfig);

// klaro! reload
let manager = window.klaro.getManager()
manager.watch({
  update: function(manager, eventType){
        if(eventType == 'saveConsents' || manager==1 ){
            location.reload()
        }
  }
})