<template>
  <div class="" role="Language">
    <select v-model="$i18n.locale" class="form-select m-1 custom-select">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale"> {{ $t("languages." + locale) }}</option>
    </select>
  </div>  
</template>

<script>
export default {

}
</script>

<style>
.custom-select:hover {
  background-color: #ABABAB;
  border: 1px solid#ABABAB;
  color: white;;
}

.custom-select {
  appearance: none;
  background-color: transparent;
  border: 1px solid#ABABAB;
  color: #ABABAB;
  /* padding: 0 1em 0 0; */
  /* margin: 0; */
  /* width: 100%; */
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
}

</style>