// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
export default {

    setFeaturedArticles(state, payload){
        state.featuredArticles = payload
    },

    setLastUpdateFeaturedArticles(state, payload){
        state.lastUpdateFeaturedArticles = payload
    },

    setTagMenu(state, payload){
        state.TagMenu = payload
    },

    setLastUpdateTagMenu(state, payload){
        state.lastUpdateTagMenu = payload
    },

    setCategoryMenu(state, payload){
        state.CategoryMenu = payload
    },

    setLastUpdateCategoryMenu(state, payload){
        state.lastUpdateCategoryMenu = payload
    }    

}