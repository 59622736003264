<template>
    
    <div v-if="$slots.default" class="p-3 pt-5">

        <div class="container-fluid"> 

            <div class="row" v-if="$slots.header">

                <div class="col-6">
                    <h2 class="mb-4" :id="anker">
                        <b>
                            <slot name="header"></slot>
                        </b>
                    </h2>
                </div>

            </div>

            <!-- <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-5 g-4 justify-content-center"  v-if="$slots.default"> -->
            <div class="row justify-content-center g-5" v-if="$slots.default">

                <slot name="default"></slot>

            </div>

        </div>

    </div>
    
</template>

<script>
export default {
    props: [
        'anker'
    ]
}
</script>