// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
export default {

    getFeaturedArticles(state){
        return state.featuredArticles        
    },

    getFeaturedArticlesForFooter(state){
        return state.featuredArticles.slice(0, 3)
    },

    getTagMenu(state){
        return state.TagMenu        
    },

    getCategoryMenu(state){
        return state.CategoryMenu        
    }        

}