<template>

    <!-- <li v-if="error && !isLoading">{{ error }}</li> -->

    <ul v-if="!error && isLoading" class="list-group list-group-flush">
        <li class="col-xs-3 list-group-item"><loading-spinner :size="0.15"></loading-spinner></li>
        <li class="col-xs-3 list-group-item"><loading-spinner :size="0.15"></loading-spinner></li>
        <li class="col-xs-3 list-group-item"><loading-spinner :size="0.15"></loading-spinner></li>
    </ul>

    <ul v-else :withLink="withLink" class="list-group list-group-flush">  

            <li v-for="article in articles" 
                :key="article.id"
                class="col-xs-3 list-group-item"
            >
                <router-link class="list-group-item" :to="{path: '/article/' + article.attributes.alias}">{{ cutTitle(article.attributes.title) }}</router-link>
            </li>

    </ul>

</template>

<script>
// import BaseErrorAlert from '../Base/BaseErrorAlert.vue';
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'

export default {
    props: ['withLink'],
    components: {
        LoadingSpinner
    },   
    data() {
        return {
            articles: {},
            isLoading: true,
            error: null
        }    
    },
    methods: {
        async getArticles(){

            this.isLoading = true;
            try {
                await this.$store.dispatch('cmsArticles/loadFeaturedArticles')
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }  
            this.articles = this.$store.getters['cmsArticles/getFeaturedArticlesForFooter']  
            this.isLoading = false;                

        },
        cutTitle(title){
            return title.split(':')[0]
        }
    },
    mounted() {
        this.getArticles();
    }
}
</script>