<template>
<!-- <span class="loading-wrapper">
  <span class="loading-text">IAMGE</span>
  <span class="loading-circle circle"></span>
  <span class="loading-circle-small circle"></span>
</span> -->
<span id="loadingSpinner" :style="style"></span>
</template>

<script>
export default {
    props: [
        'size'
    ],      
    computed:{
        style(){
            if(this.size) {
                return "--size: " + this.size + ";"
            }
            return "--size: 1;"
        }
    }  
}
</script>

<style lang="css" scoped>

#loadingSpinner {
    display: inline-block;
    width: calc(8vw * var(--size));
    height: calc(8vw * var(--size));
    border: calc(0.6vw * var(--size)) solid #85B3DD;
    border-radius: 50%;
    border-top-color: #F67CA1;
    border-bottom-color: #B389D6;
    animation: spin 1.2s ease-in-out infinite;
    -webkit-animation: spin 1.2s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

@keyframes spinBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 

</style>