<template>

<nav class="navbar navbar-expand-lg" :class="overlayClasses">

    <div class="container-fluid">

        <router-link class="navbar-brand" to="/"><img :src="require(`@/assets/images/basics/logo.png`)" alt="events4us Logo" width="45" height="45"/></router-link>

        <button class="navbar-toggler" :class="overlayMobileButtonClasses" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button> 

        <!-- <div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown"> -->
        <div class="collapse navbar-collapse" id="navbarNavDropdown">

            <ul class="navbar-nav my-2 my-lg-0 navbar-nav-scroll me-auto" style="--bs-scroll-height: 200px;">
                    <li class="nav-item"><router-link class="nav-link" to="/">{{ $t('navbar.home') }}</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/articles">{{ $t('navbar.articles') }}</router-link></li>
                    <li v-if="isLoggedIn" class="nav-item"><router-link class="nav-link" to="/articles"><u>{{ $t('navbar.bookmarks') }}</u></router-link></li>
                    <li v-if="isLoggedIn" class="nav-item"><router-link class="nav-link" to="/articles"><u>{{ $t('navbar.events') }}</u></router-link></li>
            </ul>
            
            <!-- <div class="d-flex" role="Auth">             -->
                <!-- <the-auth-navbar></the-auth-navbar>                 -->
                <the-language-navbar></the-language-navbar>                
            <!-- </div> -->

        </div>      

     

    </div>

</nav>

</template>

<script>
// import TheAuthNavbar from './TheAuthNavbar.vue'
import TheLanguageNavbar from './TheLanguageNavbar.vue'

export default {
    props: ['theme'],
    components: {
        // TheAuthNavbar,
        TheLanguageNavbar
    },    
    data(){
        return {
            isLoggedin: null
        }
    }, 
    methods: {
        // getLoginState(){            
        //     this.isLoggedin = this.$store.getters['userAuthentication/isUserLoggedIn']            
        // }
    },       
    computed: {
        overlayClasses() {
            return this.theme == 'overlay' ? '' : 'navbar-dark bg-dark'
        },
        overlayMobileButtonClasses() {
            return this.theme == 'overlay' ? 'navbar-dark' : ''
        },
        isLoggedIn() {
            return this.$store.getters['userAuthentication/isUserLoggedIn'];
        }   
    }
}
</script>

<style scoped>
    /* .navbar-nav {
      margin: 0 auto;
      padding-left: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    } */
</style>