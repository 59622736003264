<template>
    <div class="boxlink col-12 col-md-6 col-lg-4 col-xxl-3 px-3">

        <router-link :to="'/article/' + alias">
            <div class="card article-card shadow-sm w-100 h-100" style="min-height: 150px;">
                
                <svg v-if="!image_intro" class="bd-placeholder-img card-img-top" width="100%" height="135" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Article Image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="40%" y="50%" fill="#eceeef" dy=".3em">Events 4 Us</text></svg>                

                <img v-if="image_intro" :src="image_intro">

                <div class="card-body">

                    <div class="card-title">
                        <h3 v-html="title"></h3>
                    </div>

                    <div class="card-text">
                        <p v-html="textComputed"></p>
                    </div>
                    
                    <pseud 
                        v-for="tag in tags" 
                        :key="tag.id" 
                    >
                        <router-link class="btn btn-info btn-sm m-1" :to="'/articles/tag/'+tag" style="color: white">#{{ tag }}</router-link>&nbsp;
                    </pseud>   

                </div>                            
            </div>
        </router-link >
    </div>
</template>

<script>
export default {
    props: ['alias', 'image_intro', 'title', 'text', 'tags'],
    computed: {
        textComputed() {            
            var clearText
            var maxlenght = 150
            clearText = this.text.replace(/<\/?[^>]+(>|$)/g, "");
            if(clearText.length < maxlenght){
                clearText = clearText.substring(0, 150)
            } else {
                clearText = clearText.substring(0, 150) + "\u2026"
            }
            return clearText
            
        }   
    } 
}
</script>

<style scoped>

h3 {
    font-size: 20px;
    font-weight: bold;
}

.boxlink a:link,
a:visited,
a:hover,
a:active {
    color: black;
    text-decoration: none;
}

.article-card {
    /* min-height: 675px; */
    padding: 15px;
    /* border: 1px solid red; */
    text-align: center;
}

</style>