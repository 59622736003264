<template>

    <!-- NavBar with Overlay-->
    <the-navbar theme=""></the-navbar>

    <br>


    <div class="justify-content-center m-3">
        <div class="row">    
            <div class="col">            
                <p class="text-center m-2" style="font-size: 150px">
                    <font-awesome-icon icon="fa-solid fa-skull" />
                </p>              
            </div>
        </div>
        <div class="row">
            <div class="col">                
                <p class="fs-1 text-center my-2">
                    404 - {{ $t('notFound.mainMessage') }}  
                </p>
                <p class="text-center">
                    <a onclick=history.back() class="btn btn-lg btn-primary alert-btn">{{ $t('notFound.back') }}  </a>
                </p>                  
            </div>
        </div>
    </div>


    <div class="d-flex justify-content-center fs-5 my-5 mx-2">
        <div class="row">    
            <div class="col">
                <p>{{ $t('notFound.reasons') }}  </p>
                <ul>
                    <li>{{ $t('notFound.reason1') }}  </li>
                    <li>{{ $t('notFound.reason2') }}  </li>
                    <li>{{ $t('notFound.reason3') }}  </li>
                </ul>
                <br>
                <p class="text-center">{{ $t('notFound.helpText') }}<br><br><br> 
                
                    <a :href="link" class="btn btn-info btn-lg">{{ $t('notFound.sendMail') }}</a>

                </p>
            </div>
        </div>
    </div>  



    <!-- <teleport to="body">
        <base-error-alert>404 - Oops, die Seite scheint es nicht mehr zu geben.</base-error-alert>
    </teleport>     -->

</template>

<script>
export default {
    data(){
        return {
            link: null
        }
    },
    computed: {
        title(){
            return this.$i18n.t('home.title') + ' | 404 | events4us'
        }
    },
    watch: {
        title(){
            document.title = this.$i18n.t('home.title') + ' | 404 | events4us'
        }
    },
    mounted() {
        document.title = this.$i18n.t('home.title') + ' | 404 | events4us'
        this.link = "mailto:info@margold.de?subject=404%20-%20Error:%20www.events-4-us.com&body=Hi,%0A%0Aich%20habe%20einen%20Fehler%20auf%20der%20Webseite%20gefunden,%20als%20ich%20folgenden%20Link%20aufgerufen%20habe:%20https://events-4-us.com" + this.$route.fullPath
    }
}
</script>