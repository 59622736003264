// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
// 
// context {
//     commit      => to a mutation;
//     dispatch    => another action;
//     getters     => get state from a getter;
//     state       => get state from module;
//     rootGetters => use a getter from root module (or anoter module);
//     rootState   => get a state from root module (or anoter module)  ;  
// }
// import axios from 'axios'
import Cookies from 'js-cookie';

export default {

    async changeLoginState(context, newState){

        context.commit('changeLoginState', newState)
        
    },

    async checkAuthentication(context) {

        var isAuthenticated = false
        const cookieName = 'user_state';

        if (Cookies.get(cookieName) == 'login=true') {
            isAuthenticated = true
        }        

        context.dispatch('changeLoginState', isAuthenticated);
    
        // Schedule the next check after a delay (every 5 seconds)
        setTimeout(() => {                        
            context.dispatch('checkAuthentication');
        }, 5000);
    }

}