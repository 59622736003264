<template>
    <div></div>
    <!-- // ONLY USE STYLE ELEMENTS AT THIS TIME // -->
    <!-- <div class="dataprotection" @click="showKlaro">
        <font-awesome-icon :icon="['fas', 'cookie-bite']" />
    </div> -->
</template>

<script>

// export default {
//     methods: {
//         showKlaro(){
//             window.klaro.show();
//         }       
//     }
// }
</script>

<style>
.dataprotection{
    cursor: pointer;
    position: fixed; /* Sit on top of the page content */
    border: 1px solid #85B3DD;
    border-radius:100px;  
    background-color: #85B3DD;
    color: white;
    left: 15px;
    bottom: 15px;
    z-index: 900;
    text-align: center;
    padding: 2px;
    font-size: 30px;
    width:50px;
    height:50px;    
    /* box-shadow: 2px -2px  rgba(0,0,0,0.6);
    -moz-box-shadow: 2px -2px  rgba(0,0,0,0.6);
    -webkit-box-shadow: 2px -2px  rgba(0,0,0,0.6);
    -o-box-shadow: 2px -2px  rgba(0,0,0,0.6); */  
}

.cm-header a:active,
.cm-header a:visited,
.cm-header a:link,
.cm-header a:focus,
.cm-header a:hover {
    color: #6EEC96 !important;
}

.cm-body a:active,
.cm-body a:visited,
.cm-body a:link,
.cm-body a:focus,
.cm-body a:hover {
    color: #6EEC96 !important;
}

.cm-btn {
    /* background-color: var(--green1, #1a936f) !important; */
    background-color: var(--green1, #85B3DD) !important;
    
}

.cm-btn-accept-all {
    background-color: var(--green1, #1a936f) !important;
    /* background-color: var(--green1, #85B3DD) !important; */
    
}

</style>