<template>

    <base-error-alert v-if="error && !isLoading">{{ error }}</base-error-alert>

    <div v-else-if="isLoading" class="text-center pb-5 pt-4">
        <loading-spinner></loading-spinner>
    </div>    

    <article-preview-featured v-else-if="!error && !isLoading && articles.length > 0" :withLink="withLink">  

        <template v-slot:header>{{ $t('cmsArticles.newestArticles') }}</template>

        <template v-slot:default>

            <article-preview-item-small 
                v-for="article in articles" 
                :key="article.id"
                :alias="article.attributes.alias" 
                :image_intro="article.attributes.images.image_intro"
                :title="article.attributes.title"
                :text="article.attributes.text"
                :tags="article.attributes.tags"
            >
            </article-preview-item-small>
            
        </template>
        
    </article-preview-featured>

    <div v-else>
        &nbsp;<br>
        &nbsp;<br>
        &nbsp;<br>
        &nbsp;<br>
    </div>

</template>

<script>
import ArticlePreviewFeatured from '@/components/Articles/ArticlePreviewFeatured.vue';
import ArticlePreviewItemSmall from '@/components/Articles/ArticlePreviewItemSmall.vue';
// import LoadingArticles from '@/components/Articles/LoadingArticles.vue'
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'
import BaseErrorAlert from '../Base/BaseErrorAlert.vue';


export default {
    props: ['withLink'],
    components: {
        ArticlePreviewFeatured,
        ArticlePreviewItemSmall,
        LoadingSpinner,
        BaseErrorAlert
    },
    data() {
        return {
            articles: {},
            isLoading: true,
            error: null
        }    
    },
    methods: {
        async getArticles(payload=null){

            if(!payload){
                payload = { force: false }
            }

            this.isLoading = true;
            try {
                await this.$store.dispatch('cmsArticles/loadFeaturedArticles', payload)
                 
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }   
            this.articles = this.$store.getters['cmsArticles/getFeaturedArticles'] 
            this.isLoading = false;                

        }
    },
    computed: {
        languageLang(){
            return this.$store.getters['userLanguage/getLanguageLang']
        }
    },
    watch: {
        languageLang(){
            this.getArticles({force: true})
        }
    },
    mounted() {
        this.getArticles()  
    }      
}
</script>