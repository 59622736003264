<template>

    <!-- NavBar with Overlay-->
    <the-navbar theme="overlay"></the-navbar>

    <!-- Hero Section with Overlay -->
    <the-hero-section theme="overlay" align="left">

        <h2 class="">{{ $t('home.heroTextHeadline') }}</h2>
        <p class="d-none d-md-block">
            {{ $t('home.heroTextBody') }}
        </p>        
        <div class="d-grid gap-2 col-2">
            <router-link class="btn btn-primary" to="/articles">{{ $t('home.heroTextButtonArticles') }}</router-link>
        </div>   

    </the-hero-section>

    <!-- Featrued Articles -->
    <featured-article-preview withLink="True"></featured-article-preview>

    <!-- Advertisement  -->
    <paragraph-block backgroundColor="dark">

        <!-- <template v-slot:header>
            Werbung
        </template>
        <template v-slot:default>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
        </template> -->

    </paragraph-block>

    <!-- How it all started  -->
    <paragraph-block backgroundColor="">

        <template v-slot:header>
            {{ $t('home.howItAllStartedHeadline') }}
        </template>
        <template v-slot:default>
            <p>{{ $t('home.howItAllStartedBody1') }}</p>
            <p>{{ $t('home.howItAllStartedBody2') }}</p>
        </template>
            
    </paragraph-block>

</template>

<script>

    import FeaturedArticlePreview from '@/components/Articles/FeaturedArticlePreview.vue'
    import TheHeroSection from '@/components/Layout/TheHeroSection.vue'
    import ParagraphBlock from '@/components/Home/ParagraphBlock.vue'

    export default {
        name: 'PageHome',
        components: {
            TheHeroSection,
            FeaturedArticlePreview,
            ParagraphBlock
        },
        computed: {
            title(){
               return this.$i18n.t('home.title') + ' | events4us'
            }
        },
        watch: {
            title(){
                document.title = this.$i18n.t('home.title') + ' | events4us'
            }
        },
        mounted() {
            document.title = this.$i18n.t('home.title') + ' | events4us'
        }
    }

</script>

<style scoped>
</style>
