// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

export default {
    namespaced: true,
    state() {
        return {
            loginState: false,       
        }        
    },
    actions,
    getters,
    mutations
}