<template>

<div class="tag-container">

    <img src="@/assets/images/tags/hashtag_in_einem_kontainer.jpg" class="tag-header-image">  

    <div class="tag-header-text">

            <div class="row">

                <div class="col-12">

                    <h1 class="text-center">#<slot name="headline"></slot></h1><br>

                    <p class="text-center"><slot name="default"></slot></p>

                </div>                  
                

            </div>

    </div>

</div>  

</template>

<script>
export default {
    props: [
        'headline'
    ]
}
</script>

<style scoped>

    .tag-container {
        height: 500px;   
    }

    .tag-header-image {
        width: 100vw;
        height: 500px;
        object-fit: cover; 
        filter: brightness(50%);         
    }   

    .tag-header-text {
        color: rgb(255, 255, 255);
        /* border: solid 1px red; */
        position: relative;
        /* left: 5vw; */        
        width: 100%;        
        /* background-color:rgba(0, 0, 0, 0.2); */
        padding: 10px;
        top: -350px;
        border-radius: 5px;
    }  

</style>
