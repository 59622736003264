// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

export default {
    namespaced: true,
    state() {
        return {
            cacheTimeInSec: 0,              // HAVE TO BE 0 otherwise language change has errors
            featuredArticles: null,
            lastUpdateFeaturedArticles: 0,
            categoryMenu: null,
            lastUpdateCategoryMenu: 0,
            tagMenu: null,
            lastUpdateTagMenu: 0,            
        }        
    },
    actions,
    getters,
    mutations
}