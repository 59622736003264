<template>

    <!-- NavBar with Overlay-->
    <the-navbar></the-navbar>

        
    <tag-navigation withLink="true"></tag-navigation>

    <tag-headline>
        <template v-slot:headline>                      
            {{ tagName }}
        </template>
    </tag-headline>

    <div v-if="isLoading" class="text-center pt-5 pb-5">
        <loading-spinner></loading-spinner>
    </div>
    
    <!-- 2nd Level Categories -->
    <second-level-article-preview
        :anker="tagName"  
        v-if="!isLoading"   
    >
        <!-- <template v-slot:header>{{ tagName }}</template> -->
        <template v-slot:default>

            <article-preview-item-small
                v-for="article in articles" 
                :key="article.id"                
                :alias="article.attributes.alias" 
                :image_intro="article.attributes.images.image_intro"
                :title="article.attributes.title"
                :text="article.attributes.text"
                :tags="article.attributes.tags"
            >
            </article-preview-item-small>
            
        </template>   

    </second-level-article-preview>   

</template>

<script>
    
    import axios from 'axios'
    import TagNavigation from '@/components/Articles/TagNavigation.vue'     
    import TagHeadline from '@/components/Articles/TagHeadline.vue'
    import SecondLevelArticlePreview from '@/components/Articles/SecondLevelArticlePreview.vue'
    import ArticlePreviewItemSmall from '@/components/Articles/ArticlePreviewItemSmall.vue'
    import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'

    export default {

        components: {
            TagNavigation,
            TagHeadline,
            SecondLevelArticlePreview,
            ArticlePreviewItemSmall,
            LoadingSpinner
        },
        props: ["tagName"],
        data() {
            return {
                articles: {},
                isLoading: true
            }    
        },
        methods: {
            getArticles() {
                console.log(this.$store.getters['userLanguage/getLanguageLang'])
                this.isLoading = true;
                const apiServer = process.env.VUE_APP_API_SERVER;
                const url = apiServer + '/cms/articles/tag/' + this.$store.getters['userLanguage/getLanguageLang'] + '/' +  this.tagName                 
                
                axios.get(url)
                .then((response) => {                
                    return response.data;
                })
                .then((data) => {                
                    this.isLoading = false;
                    this.articles = data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.error = 'Unbekannter Fehler';
                    if(error.code == 'ERR_BAD_REQUEST'){
                        this.error =  '404 - Konnte Daten vom Server nicht abrufen.' 
                    }                
                });
            },
            setPageTitle(){
                document.title = this.tagName + ' | ' + this.$i18n.t('cmsArticles.tags') + ' | events4us'
            }
        },
        mounted() {
            this.getArticles(),
            this.setPageTitle()
        },
        watch: {
            '$route.path': ['getArticles', 'setPageTitle']
        }       
    }
</script>

<style scoped>
  .boxlink a:link, .boxlink a:visited {
    color: black;
    text-decoration: none;
  }
</style>
