<template>

    <the-navbar></the-navbar>

    <!-- Category options -->
    <category-navigation withLink="true">
    </category-navigation>
    
    <!-- Categoriy headline -->
    <category-headline :subCategories="subCategories" :image="currentCategory.category.attributes.params.image">

        <template v-if="currentCategory.category.attributes.title != false" v-slot:headline>                      
            {{ currentCategory.category.attributes.title }}
        </template>
        <!-- <template v-slot:default>Hier gibt es tolle Infos für deine nächste {{ currentCategory.attributes.title }}!</template>     -->

    </category-headline>

    <!-- 1nd Level Categories -->
    <second-level-article-preview v-if="currentCategory.articles.length > 0">

        <template v-slot:default>

            <article-preview-item-small                
                v-for="article in currentCategory.articles" 
                :key="article.id"                 
                :alias="article.attributes.alias" 
                :image_intro="article.attributes.images.image_intro"
                :title="article.attributes.title"
                :text="article.attributes.text"
                :tags="article.attributes.tags"
            >
            </article-preview-item-small>
            
        </template>   

    </second-level-article-preview>

    <div v-if="isLoading" class="text-center pt-5 pb-5">
        <loading-spinner></loading-spinner>
    </div>

    <!-- 2nd Level Categories -->
    <div v-if="!isLoading">
        <second-level-article-preview        
            v-for="subcat in subCategories" 
            :key="subcat.id"       
            :anker="subcat.category.attributes.alias"          
        >
            <template v-if="subcat.articles.length > 0" v-slot:header>{{ subcat.category.attributes.title }}</template>
            <template v-if="subcat.articles.length > 0" v-slot:default>

                <article-preview-item-small
                    v-for="article in subcat.articles" 
                    :key="article.id"                 
                    :alias="article.attributes.alias" 
                    :image_intro="article.attributes.images.image_intro"
                    :title="article.attributes.title"
                    :text="article.attributes.text"
                    :tags="article.attributes.tags"
                >
                </article-preview-item-small>
                
            </template>   

        </second-level-article-preview>
    </div>

</template>

<script>
    import axios from 'axios'
    import CategoryHeadline from '@/components/Articles/CategoryHeadline.vue'
    import CategoryNavigation from '@/components/Articles/CategoryNavigation.vue'
    import SecondLevelArticlePreview from '@/components/Articles/SecondLevelArticlePreview.vue'
    import ArticlePreviewItemSmall from '@/components/Articles/ArticlePreviewItemSmall.vue'
    import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'   
    
    export default {
        components: { 
            CategoryHeadline,
            CategoryNavigation,
            SecondLevelArticlePreview,
            ArticlePreviewItemSmall,
            LoadingSpinner
        },
        props: ["categorieName"],
        data() {
            return {
                currentCategory: {
                    category: {
                        attributes: {
                            title: false,
                            params: {
                                image:''
                            }
                        } 
                    },
                    articles: {}                                
                },
                subCategories:{                    
                },
                isLoading: true
            }    
        },
        methods: {
            getArticles() {

                this.isLoading = true;
                const apiServer = process.env.VUE_APP_API_SERVER;
                const url = apiServer + '/cms/categories/simple/' + this.$store.getters['userLanguage/getLanguageLang'] + '/' +  this.categorieName 
                
                axios.get(url)
                .then((response) => {    
                    // console.log(response)            
                    return response.data;
                })
                .then((data) => {
                    this.isLoading = false;   
                    this.currentCategory = data.currentCategory
                    this.subCategories = data.subCategories
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.error = 'Unbekannter Fehler';
                    if(error.code == 'ERR_BAD_REQUEST'){
                        this.error =  '404 - Konnte Daten vom Server nicht abrufen.' 
                    }              
                });
            },
            setPageTitle(){
                // console.log(this.currentCategory)
                document.title = this.currentCategory.category.attributes.title + ' | ' + this.$i18n.t('cmsArticles.categories') + '  | events4us'
            }
        },
        mounted() {
            this.getArticles();
        },
        watch: {
            '$route.path': ['getArticles'],
            isLoading(){
                this.setPageTitle()
            }     
        }           
    }
</script>

<style scoped>

.article-card {
    border: 20px solid white;
    
}

</style>
