<template>

    <teleport to="body">
        <base-info-alert v-if="infoAlert"></base-info-alert>
    </teleport>

    <!-- <div v-if="alert" @click="disableAlert">
        <p style="color: red;">You are logged out!</p>
    </div> -->

    <main role="main">

        <router-view />  

    </main>

    <the-data-protection></the-data-protection>
    <the-footer></the-footer>

</template>

<script>

import TheFooter from '@/components/Layout/TheFooter.vue';
import TheDataProtection from '@/components/Layout/TheDataProtection.vue'
import BaseInfoAlert from '@/components/Base/BaseInfoAlert.vue'
import router from './router';

export default {

    components: {
        TheFooter,
        TheDataProtection,
        BaseInfoAlert
    },
    data() {
        return {
            // alert: null
        }
    },
    computed: {
        // set conputed property for watch action
        isUserLoggedIn() {            
            return this.$store.getters['userAuthentication/isUserLoggedIn'];
        },
        infoAlert() {
            return this.$store.getters['userAlerts/getInfoAlertMessage'];
        }
    },   
    watch: {
        // react to computed property
        isUserLoggedIn(curValue) {  
            // only execure on Logout actions
            if (!curValue) {
                this.$store.dispatch('userAlerts/changeInfoAlertMessage', this.$i18n.t('auth.youAreLoggedOut') )
                // onyl redirect to / if requiresLogin is set to true
                if(this.$route.meta.requiresLogin && this.$route.meta.requiresLogin == true){                    
                    this.$router.replace('/');
                }                
            }
        },
        '$i18n.locale': function(newVal) {
            // console.log('locale change', newVal)            
            router.push('/')
            .then(() => {
                setTimeout(() => {
                    this.$store.dispatch('userLanguage/setLanguage', newVal)},
                    100
                )
            })
        }

    },
    created(){
        this.$store.dispatch('userAuthentication/checkAuthentication'); 
    }
    
} 

</script>
