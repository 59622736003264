<template>

    <div class="p-3">

        <div class="container-fluid"> 

            <div class="row" v-if="$slots.header">

                <div class="col-12 col-sm-6">
                    <h2 class="mb-4">
                        <b>
                            <slot name="header"></slot>
                        </b>
                    </h2>
                </div>
                
                <div v-if="withLink" class="col text-end">      
                    <router-link class="btn btn-primary btn-sm" to="/articles">{{ $t('cmsArticles.moreArticles') }}</router-link>
                </div>

                <div class="d-block d-sm-none d-md-none col-12 col-md-6">
                    &nbsp;
                </div>                

            </div>

            <!-- <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-5 g-4 justify-content-center"  v-if="$slots.default"> -->
            <div class="row g-4 justify-content-center">

                <slot name="default"></slot>

            </div>

        </div>

    </div>
    
</template>

<script>
export default {
    props: ['withLink']
}
</script>