import { createWebHistory, createRouter } from "vue-router";
import store from '@/store'
import i18n from "@/i18n";

import Home from "@/views/Home.vue";
import ImpressumView from "@/views/Pages/impressum-view.vue";
import DatenschutzView from '@/views/Pages/datenschutz-view.vue'
import ArticleTag from "@/views/Articles/article-tag.vue";
import ArticleView from "@/views/Articles/article-view.vue";
import ArticleIndex from "@/views/Articles/article-index.vue";
import ArticleCategory from "@/views/Articles/article-category.vue";
import NotFound from "@/views/NotFound.vue";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/datenschutz",
      name: "datenschutz",
      component: DatenschutzView,
    },  
    {
      path: "/impressum",
      name: "impressum",
      component: ImpressumView,
    },  
    {
      path: "/articles",
      name: "articles",
      component: ArticleIndex,
      props: false
    },  
    {
      path: "/articles/category/:categorieName",
      name: "article-category",
      component: ArticleCategory,
      props: true
    },  
    {
      path: "/articles/tag/:tagName",
      name: "article-tag",
      component: ArticleTag,
      props: true
    },    
    {
      path: "/article/:slug",
      name: "article",
      component: ArticleView,
      props: true,
      // meta: { requiresLogin: true }
    },
    {
      path: "/:catchAll(.*)",
      component: NotFound,
    },  
  ],
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});

router.beforeEach(function(to, _, next) {
  
  if (to.meta.requiresLogin && !store.getters['userAuthentication/isUserLoggedIn']) {    
    // where to route if not logged in
    store.dispatch('userAlerts/changeInfoAlertMessage', i18n.global.t('auth.youNeedToBeLoggedIn') )
    // next('/'); # DO NOT REDIRECT AT THE MOMENT - JUST SHOW ERROR MESSAGE
  } else {
    next();
  }
});

export default router;