<template>

<div class="py-5" :class="backgroundColorClass">
<!-- <div class="py-5 bg-light bg-text-light"> -->
    
    <div class="container">  

        <h2 class="display-6 mb-4"><slot name="header"></slot></h2>
        <div>
            <slot name="default"></slot>
        </div>

    </div>

</div>
    
</template>

<script>

export default {
    props: {
        'backgroundColor': {}, 
    },
    computed: {
      backgroundColorClass(){
        
         return this.backgroundColor == 'dark' ? 'bg-dark bg-text-dark text-white' : ''

      }
    }
}
</script>