<template>

    <div v-if="isLoading" class="text-center">
        <loading-spinner :size="0.2"></loading-spinner>&nbsp;
        <loading-spinner :size="0.2"></loading-spinner>&nbsp;
        <loading-spinner :size="0.2"></loading-spinner>
    </div>

    <div v-if="tags.length > 0 && !isLoading" class="p-2">

        <div class="d-flex justify-content-center text-center"> 
                            
            <div class="col">
                <router-link v-if="withLink == 'true'" class="btn btn-secondary m-1" to="/articles">#{{ $t('cmsArticles.back') }}</router-link>
                <router-link v-for="tag in tags" :key="tag.id" class="btn btn-info m-1" :to="'/articles/tag/'+tag.name">#{{ tag.name }}</router-link>
            </div>     

        </div>

    </div>  

    <div v-else>
        <!-- no tags found -->
    </div>    

</template>
<script>
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'

export default {
    components:{
        LoadingSpinner
    },
    data(){
        return {
            tags:{},
            isLoading: true,
            error: null
        }
    },
    props: [
        'withLink'
    ],
    methods: {
        async getTags(payload=null){

            if(!payload){
                payload = { force: false }
            }

            this.isLoading = true;
            try {
                await this.$store.dispatch('cmsArticles/loadTagMenu', payload)
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }  
            this.tags = this.$store.getters['cmsArticles/getTagMenu']  
            this.isLoading = false;       
                              
        }       
    },
    computed: {
        languageLang(){
            return this.$store.getters['userLanguage/getLanguageLang']
        }
    },
    watch: {
        languageLang(){
            this.getTags({force: true})
        }
    },    
    mounted() {
        this.getTags();
    }
}
</script>