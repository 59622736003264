<template>
<br>
<!-- Footer -->
<div id="footer" class="bg-dark text-bg-dark footer container-fluid">
    
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 g-4 justify-content-center">
        
        <div class="addition-footer pb-1">
            <h2>{{ $t('footer.newestArticles') }}</h2>
            <hr class="">
            <footer-featured-article-links></footer-featured-article-links>
        </div>

        <div class="addition-footer pb-1">
            <h2>{{ $t('footer.usefulLinks') }}</h2>
            <hr class="">
            <ul class="list-group list-group-flush">
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://www.eventplanner.de/" target="_blank">Eventplanner</a></li>
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://hey-happy-day.de" target="_blank">Hey Happy Day</a></li>
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://www.ticketmaster.de/" target="_blank">Ticketmaster</a></li>
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://www.tripadvisor.de/" target="_blank">Tripadvisor</a></li>
            </ul>
        </div>

        <div class="addition-footer pb-1">
            <h2>{{ $t('footer.usefulLinks') }}</h2>
            <hr class="">
            <ul class="list-group list-group-flush">
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://tourismus.nuernberg.de/" target="_blank">Tourismus Nünberg</a></li>
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://www.luebeck-tourismus.de/" target="_blank">Tourismus Lübeck</a></li>
                <li class="col-xs-3 list-group-item"><a class="list-group-item" href="https://bismarckbraeu.de/" target="_blank">Bismarckbraeu</a></li>
            </ul>
        </div>        

        <div class="addition-footer pb-1">
            <h2>{{ $t('footer.aboutUs') }}</h2>
            <hr class="">
            <ul class="list-group list-group-flush">
                <li class="col-xs-3 list-group-item"><router-link class="list-group-item" to="/impressum">{{ $t('footer.imprint') }}</router-link></li>
                <li class="col-xs-3 list-group-item"><router-link class="list-group-item" to="/datenschutz">{{ $t('footer.dataprotection') }}</router-link></li>
                <li class="col-xs-3 list-group-item">&nbsp;</li>
                <li class="col-xs-3 list-group-item">&nbsp;</li>
            </ul>
        </div>             

    </div>

</div>
</template>

<script>
import FooterFeaturedArticleLinks from '../Articles/FooterFeaturedArticleLinks.vue';

export default {
    components: {
        FooterFeaturedArticleLinks
    }
}
</script>

<style>
  .addition-footer > h2 {
    font-size: 1.2rem;
  }

    .addition-footer > ul.list-group:after {
        clear: both;
        display: block;
        content: "";
    }
  
  .addition-footer > .list-group > .list-group-item {
    border: 0 !important;
    margin: 0px;
    padding: 0px 0px;
    background-color: transparent;
    color: white
  }
  
  .addition-footer > .list-group > .list-group-item > a {
    display: block;
    padding: 5px;
    background-color: transparent;
    border: 0 !important;
    color: white
  }  
</style>