// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
// 
// context {
//     commit      => to a mutation;
//     dispatch    => another action;
//     getters     => get state from a getter;
//     state       => get state from module;
//     rootGetters => use a getter from root module (or anoter module);
//     rootState   => get a state from root module (or anoter module)  ;  
// }
// import axios from 'axios'

export default {

    async changeInfoAlertMessage(context, message){

            context.commit('changeInfoAlertMessage', message)
        
    }

}