<template>

<div class="category-container">

    <svg v-if="!image" class="category-header-image" width="100%" height="135" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Article Image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="40%" y="50%" fill="#eceeef" dy=".3em">&nbsp;</text></svg>                

    <img v-if="image" :src="image" class="category-header-image">  

    <div class="category-header-text">

            <div class="row">

                <div class="d-block d-lg-none col-12">

                    <div v-if="!!$slots.headline == false" class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>            
                    
                    <h1 class="text-center">
                        <slot name="headline"></slot>
                    </h1><br>

                    <p class="text-center"><slot name="default"></slot></p>

                </div>                  

                <div v-if="subCategoriesList.length > 0" class="col-12 col-lg-2 subMenu p-3 mb-3">
                    <b>{{ $t('cmsArticles.overview') }}</b>
                    <ol class="pt-2">
                        <li v-for="subcat in subCategoriesList" :key="subcat.id"><a :href="'#'+subcat.category.attributes.alias">{{ subcat.category.attributes.title }}</a></li>
                    </ol>
                </div>
                <div v-else>
                    &nbsp;
                </div>

                <div class="d-none d-lg-block col-lg-8">

                    <div v-if="!!$slots.headline == false" class="text-center">
                            <loading-spinner :size="0.4"></loading-spinner>
                    </div>            
                    
                    <h1 class="text-center">
                        <slot name="headline"></slot>
                    </h1><br>

                    <p class="text-center"><slot name="default"></slot></p>
                    
                </div>                

            </div>

    </div>

</div>  

</template>

<script>
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue' 

export default {
    components: {
        LoadingSpinner
    },
    props: [
        'subCategories',
        'image'
    ],
    computed: {
        subCategoriesList(){
            const array = JSON.parse(JSON.stringify(this.subCategories))
            var out = [];
            for(var i in array) {
                if(array[i].articles.length > 0){
                    out.push(array[i])
                }
            }
            return out;
        }
    }
}

</script>

<style scoped>

    .category-container {
        height: 500px;   
    }

    .category-header-image {
        width: 100vw;
        height: 500px;
        object-fit: cover; 
        filter: brightness(50%);         
    }   

    .category-header-text {
        color: rgb(255, 255, 255);
        /* border: solid 1px red; */
        position: relative;
        /* left: 5vw; */        
        width: 100%;        
        /* background-color:rgba(0, 0, 0, 0.2); */
        padding: 10px;
        top: -350px;
        border-radius: 5px;
    }  
    
    .subMenu {
        background-color:rgba(255, 255, 255, 0.5);   
        /* position: relative;   
        top: -90px; */
        /* width: 20%;         */
    }

    .subMenu a,
    .subMenu a:visited,
    .subMenu a:active,
    .subMenu a:link,
    .subMenu a:hover {
        color: rgb(255, 255, 255);
        text-decoration: none;
    }

</style>
