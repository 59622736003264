// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
// 
// context {
//     commit      => to a mutation;
//     dispatch    => another action;
//     getters     => get state from a getter;
//     state       => get state from module;
//     rootGetters => use a getter from root module (or anoter module);
//     rootState   => get a state from root module (or anoter module)  ;  
// }
import axios from 'axios'

export default {

    async loadFeaturedArticles(context, payload){
    
        const cacheTimeInSec = context.state.cacheTimeInSec
        const articles = context.state.featuredArticles
        const lastUpdate = context.state.lastUpdateFeaturedArticles
        const currentTime = new Date().getTime();
        const timeDifferenceInSeconds = (currentTime - lastUpdate) / 1000;

        if(timeDifferenceInSeconds >= cacheTimeInSec || !articles || payload.force){

            const apiServer = process.env.VUE_APP_API_SERVER;
            const url = apiServer + '/cms/articles/featured/' + context.rootGetters['userLanguage/getLanguageLang']

            try{
                const response = await axios.get(url)
                // TODO besser mit 404 testen => Anpassung an API notwendig.
                if(!("error" in response.data)){
                    context.commit('setFeaturedArticles', response.data);
                    context.commit('setLastUpdateFeaturedArticles', new Date().getTime());
                } else {
                    context.commit('setFeaturedArticles', {});  
                }
            } catch (error) {
                console.log(error)
            }
        }

    },

    async loadTagMenu(context, payload){

        const cacheTimeInSec = context.state.cacheTimeInSec
        const tagMenu = context.state.TagMenu
        const lastUpdate = context.state.lastUpdateTagMenu
        const currentTime = new Date().getTime();
        const timeDifferenceInSeconds = (currentTime - lastUpdate) / 1000;

        if(timeDifferenceInSeconds >= cacheTimeInSec || !tagMenu || payload.force){

            const apiServer = process.env.VUE_APP_API_SERVER;
            // beccause of a bug in api only use de-Tags (are the same at the moment)
            const url = apiServer + '/cms/tags/' + context.rootGetters['userLanguage/getLanguageLang']
            // const url = apiServer + '/cms/tags/de-DE'
            
            try{
                const response = await axios.get(url)
                // TODO besser mit 404 testen => Anpassung an API notwendig.
                if(response.data.constructor === Array){
                    context.commit('setTagMenu', response.data);
                    context.commit('setLastUpdateTagMenu', new Date().getTime());
                } else {
                    context.commit('setTagMenu', {});
                }
            } catch (error) {
                console.log(error)
            }             


        }

    },

    async loadCategoryMenu(context, payload){

        const cacheTimeInSec = context.state.cacheTimeInSec
        const categoryMenu = context.state.CategoryMenu
        const lastUpdate = context.state.lastUpdateCategoryMenu
        const currentTime = new Date().getTime();
        const timeDifferenceInSeconds = (currentTime - lastUpdate) / 1000;

        if(timeDifferenceInSeconds >= cacheTimeInSec || !categoryMenu || payload.force){

            const apiServer = process.env.VUE_APP_API_SERVER;
            const url = apiServer + '/cms/categories/top/' + context.rootGetters['userLanguage/getLanguageLang']
            
            try{
                const response = await axios.get(url)
                // TODO besser mit 404 testen => Anpassung an API notwendig.
                if(!(response.data.topCategories == null)){
                    context.commit('setCategoryMenu', response.data);
                    context.commit('setLastUpdateCategoryMenu', new Date().getTime());
                } else {
                    context.commit('setCategoryMenu', {});
                }
            } catch (error) {
                console.log(error)
            }    

        }

    }   

}