<template>
    <div></div>
    <dialog class="alert" open>       
        <p class="text-center m-5 fs-3">
          <font-awesome-icon icon="fa-solid fa-skull" /> <slot></slot>
        </p>
        <p class="text-center">
          <a onclick=history.back() class="btn btn-sm btn-primary alert-btn">{{ $t("alert.back") }}</a>&nbsp;&nbsp; 
          <a onclick=window.location.reload(true) class="btn btn-sm btn-primary alert-btn">{{ $t("alert.reload") }}</a>&nbsp;&nbsp; 
        </p>
    </dialog>
    
</template>

<style scoped>

.alert {
    margin: 0;
    position: fixed;
    top: 20vh;
    /* left: 30%; */
    /* width: 40%; */
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    z-index: 100;
}

/* .alert-btn {
    margin: 0;
    position: relative;
    top: 20vh;
    left: 30%;
    width: 40%;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    z-index: -110;
} */

div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

/* dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
} */

/* @media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
} */
</style>