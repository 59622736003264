import { createStore } from 'vuex';

import cmsArticles from './modules/cmsArticles'
import userAuthentication from './modules/userAuthentication';
import userAlerts from './modules/userAlerts';
import userLanguage from './modules/userLanguage'

const store = createStore({
    modules: {
        userAlerts,
        userAuthentication,        
        userLanguage,
        cmsArticles
    },
    state() {
      return {};
    }
  });
  
  export default store;