<template>

    <div v-if="isLoading" class="text-center">
        <loading-spinner :size="0.2"></loading-spinner>&nbsp;
        <loading-spinner :size="0.2"></loading-spinner>&nbsp;
        <loading-spinner :size="0.2"></loading-spinner>
    </div>

    <div v-if="topCategories.length > 0 && !isLoading" class="p-2">

        <div class="d-flex justify-content-center text-center"> 
                
            <div class="col">
                <router-link v-if="withLink == 'true'" class="btn btn-secondary m-2" to="/articles">{{ $t('cmsArticles.back') }}</router-link>
                <router-link v-for="cat in topCategories" :key="cat.id" class="btn btn-primary m-2" :to="'/articles/category/'+cat.attributes.alias">{{ cat.attributes.title }}</router-link>&nbsp; 
            </div>

        </div>

    </div>  

    <div v-else>
        <!-- no categories found -->
    </div>

</template>

<script>
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'

export default {
    components:{
        LoadingSpinner
    },
    data() {
        return {
            topCategories: {},
            isLoading: true,
            error: null   
        }
    },
    props: [
        'withLink'
    ],
    methods: {
        async getTopCategories(payload=null){

            if(!payload){
                payload = { force: false }
            }
            
            this.isLoading = true;
            try {
                await this.$store.dispatch('cmsArticles/loadCategoryMenu', payload)
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }  
            const foo = this.$store.getters['cmsArticles/getCategoryMenu']
            if("topCategories" in foo){
                this.topCategories = foo.topCategories
            }            
            this.isLoading = false;                

        }
    },
    computed: {
        languageLang(){
            return this.$store.getters['userLanguage/getLanguageLang']
        }
    },
    watch: {
        languageLang(){
            this.getTopCategories({force: true})
        }
    },    
    mounted() {
        this.getTopCategories();
    }
}
</script>