<template>

    <the-navbar theme="overlay"></the-navbar>

    <the-hero-section theme="overlay" heroImage="freunde_beim_tanzen.jpg">
            <h2 class="text-center">{{ $t('cmsArticles.heroTextHeadline') }}</h2>
            <p class="d-none d-md-block text-center">
                {{ $t('cmsArticles.heroTextBody1') }}<br><br>
                {{ $t('cmsArticles.heroTextBody2') }}
            </p>
    </the-hero-section>

    <div class="container-fluent pt-3">

        <h2 class="text-center">{{ $t('cmsArticles.chooseACategorieOrTag') }}</h2>
        <br>

        <!-- Category options -->
        <categoriy-navigation withLink="false">
        </categoriy-navigation>        

        <!-- Tag options -->
        <tag-navigation withLink="false">
        </tag-navigation>    

    </div>    

<br>

<FeaturedArticlePreview withLink="" />


</template>

<script>
    import TheHeroSection from '@/components/Layout/TheHeroSection.vue'
    import CategoriyNavigation from '@/components/Articles/CategoryNavigation.vue' 
    import TagNavigation from '@/components/Articles/TagNavigation.vue'      
    import FeaturedArticlePreview from '@/components/Articles/FeaturedArticlePreview.vue'
    
    export default {
        components: { 
            FeaturedArticlePreview,
            CategoriyNavigation,
            TagNavigation,
            TheHeroSection
        },
        mounted() {
            document.title = this.$i18n.t('cmsArticles.articles') + ' | events4us'
        }
    }
</script>