<template>

    <!-- NavBar -->
    <the-navbar></the-navbar>   

    <base-error-alert v-if="error && !isLoading">{{ error }}</base-error-alert> 

    <div v-if="!error" class="container">

        <div v-if="isLoading" class="text-center pt-5 pb-5">
            <loading-spinner></loading-spinner>
        </div>   

        <div v-else>

            <div class="row">
                <div class="col-12 pt-3">
                    <a onclick=history.back() class="btn btn-sm btn-secondary">{{ $t('cmsArticles.back') }}</a>&nbsp;&nbsp; 
                    <font color="gray">{{ $t('cmsArticles.lastUpdate') }}: {{ modified }} | {{ author }}</font>                                    
                </div>
            </div>

            <div>

                <div class="pt-3 cms-header">

                    <img :src="article.attributes.images.image_intro" class="cms-article-intro-image">                

                    <div class="cms-header-text">
                        <h1 class="" v-html="article.attributes.title"></h1>
                        <p class="d-none d-md-block" v-html="article.attributes.teaser"></p>
                    </div>                        

                </div>                 

                <div class="cms-tag-box">
                    <router-link v-for="tag in article.attributes.tags" :key="tag.id" class="btn btn-info btn-sm me-1" :to="'/articles/tag/'+tag">#{{ tag }}</router-link>
                </div>

                <div class="cms-article-box mb-3" v-html="article.attributes.text"></div>

            </div>

        </div>


    </div>

</template>

<script>
import axios from 'axios'
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'

export default {
    components: {
        LoadingSpinner
    },
    props: ["slug"],
    data() {
        return {
            article: {
                attributes: {
                    title: "Artikel läd...",
                    text: "Loading...",
                    images: {
                        image_intro: null
                    },
                    metadata: {
                        author: null
                    },                    
                    modified: false,
                    tags: [],
                    teaser: ''
                }                
            },
            isLoading: true,
            error: null
        }    
    },
    computed: {
        modified(){
            if(this.article.attributes.modified){
                var date = new Date(this.article.attributes.modified)
                return date.toLocaleDateString('' + this.$store.getters['userLanguage/getLanguageLang'] + '',{year: 'numeric', month: '2-digit', day: '2-digit'})
            }
            else {
                return 'Loading...'
            }
        },
        author(){
            if(this.article.attributes.metadata.author){
                return this.article.attributes.metadata.author
            }
            else {
                return 'Events 4 Us Team'
            }
        }
    },
    methods: {
        getArticle() {

            this.isLoading = true;
            const apiServer = process.env.VUE_APP_API_SERVER;
            const url = apiServer + '/cms/articles/' + this.$store.getters['userLanguage/getLanguageLang'] + '/' + this.slug
            
            axios.get(url)
            .then((response) => {                
                return response.data;
            })
            .then((data) => {
                this.isLoading = false;
                this.article = data;
            })
            .catch((error) => {
                this.isLoading = false;
                this.error = 'Unbekannter Fehler';
                if(error.code == 'ERR_BAD_REQUEST'){
                    this.error =  '404 - Konnte Daten vom Server nicht abrufen.' 
                }                
            });
        },
        setPageTitle(){
            document.title = this.article.attributes.title + ' | ' + this.$i18n.t('cmsArticles.articles') + ' | events4us'
        }
    },
    mounted() {
        this.getArticle();  
    },
    watch: {
        '$route.path': 'getArticle',
        isLoading(){
            this.setPageTitle()
        }
    }  
}

</script>

<style>

.cms-article-image-fade-secondary {
        display: inline-block;
        box-shadow: 0 0 5px 5px #ABABAB;
}

.cms-article-image-fade-success {
        display: inline-block;
        box-shadow: 0 0 5px 5px #6EEC96;
}

.cms-article-image-fade-primary {
        display: inline-block;
        box-shadow: 0 0 5px 5px #85B3DD;
}

.cms-article-image-fade-info {
        display: inline-block;
        box-shadow: 0 0 5px 5px #BE8EE6;
}

</style>

<style scoped>

/* Article Box */

.cms-tag-box {
    border: 2px solid var(--info-box-border);
    background-color: var(--info-box-bgc);
    /* padding: 1rem; */
    /* min-height: 100px; */
    /* border-end-end-radius: 10px;
    border-end-start-radius: 10px; */
    /* border-radius: 0 0 10px 10px; */
    box-shadow: 0 0 10px var(--info-box-shadow);    
    padding: 10px;
}

.cms-article-box {
    border: 2px solid var(--info-box-border);
    background-color: var(--info-box-bgc);
    padding: 1rem;
    min-height: 100px;
    /* border-end-end-radius: 10px;
    border-end-start-radius: 10px; */
    border-radius: 0 0 10px 10px;
    box-shadow: 0 10px 10px var(--info-box-shadow);    
}

.cms-article-intro-image {
    /* flex-shrink: 0; */
    width: 100%;
    max-width: 100%;
    /* margin-top: var(--bs-gutter-y); */
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -4px 10px var(--info-box-shadow);  
    /* object-fit: cover;  */
    filter: brightness(50%);   
    /* height: 500px;  */
} 

.cms-header {
    position: relative;
}

.cms-header-text {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 30%;          
    width: 100%;
    text-align: center;
    padding: 2%;
}  

</style>