<template>

<div :class="overlaySectionClasses">

    <!-- <img src="@/assets/images/hero-section/unterwasser_mann_auf_stuhl.jpg" class="hero-image"> -->
    <img :src="require(`@/assets/images/hero-section/${heroImage}`)" class="hero-image">

    <div :class="[ overlayTextClasses, textAlign ]">

            <slot name="default"></slot>   

    </div>

</div>  

</template>

<script>
export default {
    props: {
        'theme': {}, 
        'align': {}, 
        'heroImage': {
            default: 'unterwasser_mann_auf_stuhl.jpg'
        }
    },
    computed: {

        overlaySectionClasses() {

            return this.theme == 'overlay' ? 'hero-overlay' : 'hero-section'

        },

        overlayTextClasses() {

            return this.theme == 'overlay' ? 'hero-overlay-text' : 'hero-text'

        },
        
        textAlign() {

            return this.align == 'left' ? 'hero-overlay-text-left' : ''

        }         

    }
}
</script>

<style scoped>

    .hero-section {
      height: 500px;
    }

    .hero-text {
        color: rgb(255, 255, 255);
        /* border: solid 1px red; */
        position: relative;
        left: 5vw;
        width: 70%;        
        /* background-color:rgba(0, 0, 0, 0.2); */
        padding: 10px;
        top: -350px;
        border-radius: 5px;
    }      

    .hero-overlay {
      height: calc(500px - 56px);     
      /* bottom: -56px; */
    }  

    .hero-overlay-text {
        color: rgb(255, 255, 255);
        /* border: solid 1px red; */
        position: relative;
        /* background-color:rgba(0, 0, 0, 0.2); */
        padding: 10px;
        top: 30%;
        border-radius: 5px;
    }    
    
    .hero-overlay-text-left {
        left: 5vw;
        width: 70%;        
    }

    .hero-overlay img {
      object-fit: cover;
      position: absolute;
      top: 0;
      z-index: -1;
      filter: brightness(60%);
    }

    .hero-image {
      width: 100vw;
      height: 500px;
      object-fit: cover;      
    }    

</style>