<template>

    <!-- NavBar -->
    <the-navbar></the-navbar>

    <div class="container">

        <div class="p-3">
            
           <h1 id="top">Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Patrick Lang<br />
            IT Management Lang<br />
            Rinnigweg 1a<br />
            91341 R&ouml;ttenbach</p>

            <h2>Kontakt</h2>
            <p>Telefon: +4991959392622<br />
            E-Mail: info)&auml;d(margold.de</p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            216/243/31564</p>

            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <br>

        </div>  

        <div class="text-end">
            <span class="text-secondary" style="font-size: 10px">Application version: {{ buildTag }}</span>
        </div>
  
    </div>    
    
</template>

<script>

export default {
    data() {
        return {
            buildTag: 'v1.3.0'
        }
    },
    mounted() {
        document.title = 'Impressum | events4us'
    }    
}
</script>