// Set State:   COMPONENT --Dispatch--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Call-API:    COMPONENT --Dispatch--> ACTIONS --Api-Call--> ACTIONS --Commit(Async)--> MUTATIONS --(Sync)--> STATE
// Get State:   STATE <---- GETTERS --Render--> COMPONENT
export default {

    setLanguage(state, language){  
        if(language == 'en'){
            state.language = 'en'
            state.languageLang = 'en-GB'  
        } 
        else if(language == 'es'){
            state.language = 'es'
            state.languageLang = 'es-ES' 
        }        
        else {
            state.language = 'de'
            state.languageLang = 'de-DE'
        }
    }
}